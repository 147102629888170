<script setup lang="ts">
interface Props {
  contrastBackground?: boolean
}

withDefaults(defineProps<Props>(), {
  contrastBackground: false,
})

const {
  value: {
    content: { body: reviews },
  },
} = await useStoryblok('reviews', { version: 'published', cv: 'latest' })

function splitString(str: string) {
  const spliitedName = str.split(';')
  if (spliitedName)
    return spliitedName

  return [str]
}
</script>

<template>
  <section>
    <div :class="contrastBackground ? 'bg-milky-200' : ''" class="w-full">
      <div
        class="max-w-[1012px] w-full px-4 mx-auto py-12 md:py-28 flex flex-row gap-10"
      >
        <div class="w-full">
          <h2 class="text-m-h2 md:text-h2 pb-2">
            Отзывы о работе Ранга
          </h2>
          <div
            class="md:columns-2 gap-10 gap-y-10 gap-x-10 w-full mt-6 md:mt-12"
          >
            <div
              v-for="(review, idx) in reviews"
              :key="idx"
              class="bg-white rounded-xl h-auto first:m-0 mt-4 md:mt-12 pl-6 pr-8 pt-5 md:px-12 md:pt-8 pb-7 md:pb-10"
            >
              <p v-text="`${review.text}.`" />
              <div class="inline-flex items-start gap-3 mt-2 md:mt-3">
                <NuxtImg
                  v-if="review.photo.filename"
                  class="aspect-square w-[60px] h-[60px] md:w-[40px] md:h-[40px]"
                  
                  loading="lazy"
                  width="60px"
                  height="60px"
                  :src="review.photo.filename"
                  provider="storyblok"
                  alt="photo"
                />
                <div class="flex flex-col text-primary-small justify-center h-[60px] md:h-[40px]">
                  <span class="font-bold">{{ review.name }}</span>
                  <div class="flex flex-col md:flex-row md:gap-1">
                    {{ review.organizationPosition }}
                    <template v-for="(name, idx) in splitString(review.organizationName)" :key="name">
                      <a
                        v-if="splitString(review.organizationWebsite)[idx]"
                        class="whitespace-nowrap"
                        :class="{ 'text-blue-500 border-b-[1px] border-blue-300 hover:opacity-70 duration-150 w-fit': splitString(review.organizationWebsite)[idx].length > 1 }"
                        :href="splitString(review.organizationWebsite)[idx]"
                        target="_blank"
                        v-text="name"
                      />
                      <p
                        v-else
                        class="whitespace-nowrap"
                        v-text="name"
                      />
                      <span v-if="idx !== splitString(review.organizationName).length - 1"> + </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NuxtLink to="/reviews">
            <RButton class="mt-4 md:mt-12 !bg-transparent" color="secondary">
              Все отзывы
            </RButton>
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss"></style>
