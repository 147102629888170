<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core/index'

interface Props {
  contrastBackground?: boolean
}

withDefaults(defineProps<Props>(), {
  contrastBackground: false,
})

const target = ref(null)
const target2 = ref(null)
const target3 = ref(null)
const target4 = ref(null)
const target5 = ref(null)
const targetIsVisible = ref(false)
const targetIsVisible2 = ref(false)
const targetIsVisible3 = ref(false)
const targetIsVisible4 = ref(false)
const targetIsVisible5 = ref(false)

useIntersectionObserver(
  target,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting
  },
)
useIntersectionObserver(
  target5,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible5.value = isIntersecting
  },
)
useIntersectionObserver(
  target2,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible2.value = isIntersecting
  },
)
useIntersectionObserver(
  target3,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible3.value = isIntersecting
  },
)
useIntersectionObserver(
  target4,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible4.value = isIntersecting
  },
)
</script>

<template>
  <section class="relative">
    <div :class="contrastBackground ? 'bg-milky-200' : ''" class="w-full">
      <div class="max-w-[1012px] w-full px-4 mx-auto pt-16 pb-[110px] flex flex-row gap-10">
        <div class="hidden lg:flex flex-row gap-20">
          <div class="sticky self-start top-20 bottom-0 flex flex-col gap-4 items-center justify-center max-w-[540px] min-h-[500px]">
            <h2 class="text-h2">
              Подстраивается<br>под настроение клиента
            </h2>
            <div v-if="!targetIsVisible2 && !targetIsVisible3 && !targetIsVisible4">
              <p class="text-primary self-start">
                Клиент открывает ссылку из СМС и отмечает, понравилось ему обслуживание или нет. В зависимости от ответа сервис предлагает разные сценарии.
              </p>
            </div>
            <div v-if="targetIsVisible2 && !targetIsVisible3">
              <p class="text-primary self-start">
                Если клиенту не понравилось, предлагаем отправить негативный отзыв напрямую директору.
              </p>
              <p class="text-primary self-start mt-2">
                Так он почувствует, что его проблему решат.
              </p>
            </div>
            <div v-if="targetIsVisible3 || targetIsVisible4">
              <p class="text-primary self-start">
                Если клиенту понравилось, предлагаем опубликовать позитивный отзыв в картах или независимых отзовиках: Яндекс.Картах, Google Maps, Zoon, Yell и других.
              </p>
              <p class="text-primary self-start mt-2">
                Там потенциальные клиенты будут выбирать вас.
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-14 w-1/2">
            <div class="relative">
              <NuxtImg
                

                loading="lazy"
                src="/img_phone.png"
                alt="phone"
              />
              <div ref="target" class="absolute top-1/2" />
            </div>
            <div class="relative">
              <NuxtImg
                

                loading="lazy"
                src="/img_phone1.png"
                alt="phone_1"
              />
              <div ref="target2" class="absolute top-1/2" />
            </div>
            <div class="relative">
              <NuxtImg
                

                loading="lazy"
                src="/img_phone2.png"
                alt="phone_2"
              />
              <div ref="target3" class="absolute top-1/2" />
              <div ref="target4" class="absolute bottom-0" />
            </div>
          </div>
        </div>
        <div class="lg:hidden block relative">
          <h2 class="text-m-h2 pb-8">
            Подстраивается<br>под настроение<br>клиента
          </h2>
          <div ref="scrollContainer" class="flex flex-row overflow-x-scroll  gap-10">
            <div class="flex-shrink-0 w-2/3 flex flex-col gap-2">
              <NuxtImg
                

                loading="lazy"
                class="w-[280px]" src="/img_phone.png"
                alt="phone_3"
              />
              <div class="text-m-primary-small font-bold">
                <p>
                  Клиент открывает ссылку из СМС и отмечает, понравилось ему обслуживание или нет.
                </p>
                <p>
                  В зависимости от ответа сервис предлагает разные сценарии.
                </p>
              </div>
            </div>
            <div class="flex-shrink-0 w-2/3  flex flex-col gap-2">
              <NuxtImg
                

                loading="lazy"
                class="w-[280px]" src="/img_phone1.png"
                alt="phone_4"
              />
              <div class="text-m-primary-small font-bold">
                <p>
                  Если клиенту не понравилось, предлагаем отправить негативный отзыв напрямую директору.
                </p>
                <p>
                  Так он почувствует, что его проблему решат.
                </p>
              </div>
            </div>
            <div class="flex-shrink-0 w-2/3  flex flex-col gap-2">
              <NuxtImg
                

                loading="lazy"
                class="w-[280px]" src="/img_phone2.png"
                alt="phone_5"
              />
              <div class="text-m-primary-small font-bold">
                <p>
                  Если клиенту понравилось, предлагаем опубликовать позитивный отзыв в картах или независимых отзовиках: Яндекс.Картах, Google Maps, Zoon, Yell и других.
                </p>
                <p>
                  Там потенциальные клиенты будут выбирать вас.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
</style>
